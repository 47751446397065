"use client";

import React, { FC, useEffect, useState } from "react";
import { RenderLayout } from "@/app/(root)/RenderLayout";
import { setLandingPage } from "@/siteConfig/settings/layoutSetting";

const Home: FC = () => {
    const [isClient, setIsClient] = useState(false);
    const layout = setLandingPage()

    useEffect(() => {
        setIsClient(true);
    }, []);

    if (!isClient) return null;

    return (
        <RenderLayout layout={layout} />
    );
};

export default Home;
