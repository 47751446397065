import { supportedLanguages } from "@/contexts/languageContext";

type LayoutType = string | { [key: string]: string };

export const defaultLayout: { [key: string]: { [key: string]: LayoutType } } = {
    header: {
        Logo: "Type2",
        SupportedLanguage: "Type2",
        AuthUserHeader: "Type1",
        AnonymousUserHeader: "Type1",
    },
    main: {
        Navigation: "Type1",
    },
    footer: {
        Partner: "Type1",
    },
};

//팝업 공지
export const popupNotificationLayout: { [key: string]: { [key: string]: LayoutType } } = {
    popupNotification: {
        PopupNotification: "Type1"
    }
}

//랜딩 페이지
export function setLandingPage() {
    const layout = {
        "landing": {
            LineNotice: "Type1",
            Banner: "Type1",
            PopulerGames: "Type1",
            LandingSports: "Type1",
            LandingSlot: "Type1",
            LandingCasino: "Type1",
            SubBanner: "Type1",
            // subBanner: {
            // Banner2: "Type1",
            // LiveDepositWithdrawals: "Type1",
            // },
        }
    }

    return layout;
}

//카지노 페이지
export function setCasinoPage() {
    const layout = {
        "casino": {
            Casino: "Type1",
        }
    }
    return layout;
}

//슬롯 페이지
export function setSlotoPage() {
    const layout = {
        "slot": {
            Slot: "Type1",
        }
    }
    return layout;
}

//스포츠 페이지
export function setSportsPage() {
    const layout = {
        "sports": {
            SportsFilterBox: "Type1",
            "sportsMain": {
                // SportsHeader: "Type1",
                SportsSearchForm: "Type1",
                SportsPrematch: "Type1",
            },
            SportsBetSlip: "Type1",
        }
    }
    return layout;
}


//스포츠 배팅내역 페이지
export function setSportsBetHistoryPage() {
    const layout = {
        "sportsBettingHistory": {
            SportsBetHistory: "Type1",
        }
    }
    return layout;
}

//홀덤 페이지
export function setHoldemPage() {
    const layout = {
        "holdem": {
            Holdem: "Type1",
        }
    }
    return layout;
}

//충전 페이지
export function setDepositPage() {
    const layout = {
        "deposit": {
            Deposit: "Type1",
        }
    }
    return layout;
}

//충전 내역 페이지
export function setDepositHistoryPage() {
    const layout = {
        "depositHistory": {
            DepositHistory: "Type1",
        }
    }
    return layout;
}

//환전 페이지
export function setWithdrawalPage() {
    const layout = {
        "withdrawal": {
            Withdrawal: "Type1",
        }
    }
    return layout;
}

//환전 내역 페이지
export function setWithdrawalHistoryPage() {
    const layout = {
        "withdrawalHistory": {
            WithdrawalHistory: "Type1",
        }
    }
    return layout;
}

//이벤트 페이지
export function setEventePage() {
    const layout = {
        "event": {
            Event: "Type1",
        }
    }
    return layout;
}

//공지사항 페이지
export function setNoticePage() {
    const layout = {
        "notice": {
            Notice: "Type1",
        }
    }
    return layout;
}

//비밀번호 변경 페이지
export function setPasswordChangePage() {
    const layout = {
        "passwordChange": {
            PasswordChange: "Type1",
        }
    }
    return layout;
}

//쪽지 페이지
export function setMessagePage() {
    const layout = {
        "message": {
            Message: "Type1",
        }
    }
    return layout;
}

//고객센터 페이지
export function setCustomerCenterPage() {
    const layout = {
        "customerCenter": {
            CustomerCenter: "Type1",
        }
    }
    return layout;
}