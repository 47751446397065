"use client"
import dynamic from 'next/dynamic';
import React, { FC, useEffect, useCallback } from "react";
import { I18nextProvider } from 'react-i18next';
import i18n from '../i18n';

export type LayoutItem = string | { [key: string]: LayoutItem };

const loadComponent = (name: string, type: string) => {
    return dynamic(() =>
        import(`@/Components/${name}/${type}`)
            .then((mod) => mod.default)
            .catch(() => {
                const FallbackComponent = () => <div>Component not found</div>;
                FallbackComponent.displayName = 'FallbackComponent';
                return FallbackComponent;
            }),
        { ssr: false }
    );
};

const LazyComponent: FC<{ name: string; type: string, isMobile: boolean }> = React.memo(({ name, type, isMobile }) => {
    const typeName = isMobile ? `${type}_Mobile` : type;
    const Component = loadComponent(name, typeName);
    return <Component />;
});

LazyComponent.displayName = 'LazyComponent';

const RenderLayoutComponent: React.FC<{ layout: { [key: string]: LayoutItem }, parentKey?: string, isMobile: boolean }> = ({ layout, parentKey, isMobile }) => {
    return (
        <I18nextProvider i18n={i18n}>
            {Object.keys(layout).map((key) => {
                const item = layout[key];
                const keyName = parentKey ? `${parentKey}-${key}` : key;
                if (typeof item === 'string') {
                    return <LazyComponent key={keyName} name={key} type={item} isMobile={isMobile} />;
                }

                if (typeof item === 'object') {
                    return (
                        <div className={key} key={keyName}>
                            <RenderLayoutComponent layout={item as { [key: string]: LayoutItem }} parentKey={key} isMobile={isMobile} />
                        </div>
                    );
                }

                return null;
            })}
        </I18nextProvider>
    );
};

export const RenderLayout: FC<{ layout: { [key: string]: LayoutItem }, parentKey?: string }> = ({ layout, parentKey }) => {
    const [isMobile, setIsMobile] = React.useState<boolean>(false);

    const resize = useCallback(() => {
        if (typeof window !== 'undefined') {
            const shouldBeMobile = window.innerWidth < 768;
            if (shouldBeMobile !== isMobile) setIsMobile(shouldBeMobile);
        }
    }, [isMobile]);

    useEffect(() => {
        if (typeof window !== 'undefined') {
            window.addEventListener('resize', resize);
            resize();

            return () => {
                window.removeEventListener('resize', resize);
            };
        }
    }, [resize]);

    return (
        <RenderLayoutComponent layout={layout} parentKey={parentKey} isMobile={isMobile} />
    );
};
